import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import PropTypes from "prop-types";

import { getNodeApiUrl, submitRequest } from "../../helpers/api";
import fetchData from "../../helpers/api-cache";
import { getUnlocalizedUrl } from "../../helpers/localized-url";
import { getBlocksFromNode, getTemplateFromNode } from "../../helpers/node";

const isProduction = process.env.NODE_ENV === "production";

// MANAGES THE PREFETCH QUEUE
const requestQueue = [];
let isProcessingQueue = false;

// MANAGES THE PREFETCH QUEUE WITH A TIMER
const processQueue = () => {
  // IF NOTHING WAITNG, EXITS
  if (isProcessingQueue || requestQueue.length === 0) {
    return;
  }

  isProcessingQueue = true;
  const delay = Math.random() * (500 - 200) + 200;

  // GET NEXT REQUEST AND EXECUTE IT
  const nextRequest = requestQueue.shift();
  nextRequest();

  // WAIT AND REPEAT
  setTimeout(() => {
    isProcessingQueue = false;
    processQueue();
  }, delay);
};

// This component wraps the `<Link />` component from react-router-dom
// and tries to prefetch the chunks of a page when the link is in the viewport and/or
// when the link is hovered.
// References:
// - https://omarelhawary.me/blog/file-based-routing-with-react-router-pre-loading
// - https://web.dev/quicklink/

const LinkPrefetch = ({ children, to, prefetch = true, title, ...props }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const ref = useRef(null);
  const [prefetched, setPrefetched] = useState(false);

  const prefetchable = Boolean(!prefetched);

  const handleNodeData = (node) => {
    // try to prefetch the template of the view and
    // the block components for the node

    const template = getTemplateFromNode(node);
    const blocks = getBlocksFromNode(node);

    if (template) {
      import(`/src/views/${template}`);
    }

    if (blocks?.length > 0) {
      for (const block of blocks) {
        import(`/src/blocks/${block}/${block}`);
      }
    }

    setPrefetched(true);
  };

  const handleError = (err) => {
    if (!isProduction) {
      console.error(err);
    }
    setPrefetched(false);
  };

  const preload = useCallback(() => {
    // We need to extract the slug to fetch the node data
    // /es/noticias/una-noticia -> /noticias/una-noticia

    let slug = getUnlocalizedUrl(to);
    slug = slug !== "/" ? slug : "/home";

    const nodeUrl = getNodeApiUrl(locale, slug);
    const agendaUrl =
      "https://kutxafundazioa.eus/es/export/get-node/0?node_path=%2Fagenda";

    // IF NODE IS RELATED TO AGENDA, IT'S FETCHED DIRECTLY WITH OLD WAY
    // IF NOT, IT'S ADDED TO THE QUEUE, STORE, MEMORY, ETC.
    if (nodeUrl === agendaUrl || nodeUrl.startsWith(agendaUrl)) {
      submitRequest(nodeUrl)
        .then((response) => response.json())
        .then(handleNodeData)
        .catch(handleError);
    } else {
      requestQueue.push(() => {
        fetchData(nodeUrl)
          .then((response) => response.json())
          .then(handleNodeData)
          .catch(handleError);
      });
      processQueue();
    }
  }, [locale, to]);

  useEffect(() => {
    if (prefetchable && prefetch && ref?.current) {
      const observer = new IntersectionObserver(
        (entries) =>
          entries.forEach((entry) => entry.isIntersecting && preload()),
        { rootMargin: "200px" }
      );

      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [prefetch, prefetchable, preload]);

  const handleMouseEnter = () => preload();

  return (
    <RouterLink
      to={to}
      ref={ref}
      onMouseEnter={handleMouseEnter}
      title={title || undefined}
      {...props}
    >
      {children}
    </RouterLink>
  );
};

LinkPrefetch.propTypes = {
  to: PropTypes.string,
  prefetch: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default LinkPrefetch;
