import { capitalizeFirstLetter, encodeHtml, isString } from "./general";
import { getUnlocalizedUrl } from "./localized-url";

const getFieldValueFromArray = (field) => {
  if (!field) {
    return "";
  }

  if (!Array.isArray(field)) {
    return field;
  }

  if (field.length <= 0) {
    return "";
  }

  return field[0];
};

const getFieldValueText = (field) => {
  if (field) {
    if (field.length > 0) {
      // is an array
      return getFieldValueFromArray(field);
    } else {
      // is a string
      return isString(field) ? field : null;
    }
  }

  return null;
};

const getFieldValueHtml = (field) => {
  const fieldValue = getFieldValueFromArray(field);

  if (!fieldValue.processed) {
    return "";
  }

  return fieldValue.processed;
};

const getFieldValueCarruselItems = (items = []) => {
  if (!items) {
    return [];
  }

  return items.map((item) => {
    const { field_multimedia } = item;
    const value = getFieldValueMultimedia(field_multimedia);

    return value;
  });
};

const getFieldValueMultimedia = (field) => {
  const fieldValue = Array.isArray(field)
    ? getFieldValueFromArray(field)
    : field;

  if (!fieldValue) {
    return null;
  }

  const { multimedia_type = "", type = "" } = fieldValue;

  if (type !== "multimedia") {
    return null;
  }

  const {
    mid,
    field_pie_de_foto = "",
    field_media_video_file = null,
    field_media_oembed_video = null,
    field_media_poster = null,
    field_media_poster_mobile = null,
  } = fieldValue;

  let multimediaData;

  const mediaPosterFieldValue =
    field_media_poster?.length > 0
      ? getFieldValueFromArray(field_media_poster)
      : null;

  const mediaPosterMobileFieldValue =
    field_media_poster_mobile?.length > 0
      ? getFieldValueFromArray(field_media_poster_mobile)
      : null;

  switch (multimedia_type) {
    case "video_loop":
      multimediaData = field_media_video_file
        ? getFieldValueMediaVideoFile(field_media_video_file)
        : null;

      break;

    case "video":
      const videoFileUrl = field_media_video_file
        ? getFieldValueMediaVideoFile(field_media_video_file)
        : null;

      multimediaData = {
        ...videoFileUrl,
        poster: [
          mediaPosterFieldValue?.url ?? "",
          mediaPosterFieldValue?.url ?? "",
          mediaPosterFieldValue?.url ?? "",
          mediaPosterMobileFieldValue?.url ?? "",
        ].filter(Boolean),
      };

      break;

    case "remote_video":
      const videoUrl = field_media_oembed_video
        ? getFieldValueMediaRemoteVideoFile(field_media_oembed_video)
        : null;

      multimediaData = {
        ...videoUrl,
        poster: [
          mediaPosterFieldValue?.url ?? "",
          mediaPosterFieldValue?.url ?? "",
          mediaPosterFieldValue?.url ?? "",
          mediaPosterMobileFieldValue?.url ?? "",
        ].filter(Boolean),
      };

      break;

    case "image":
    default:
      const { field_media_image = null } = fieldValue;

      multimediaData = field_media_image
        ? getFieldValueMediaImage(field_media_image)
        : null;

      break;
  }

  return {
    id: mid,
    footer: field_pie_de_foto,
    mediaImage: multimediaData,
    data: multimediaData,
    type: multimedia_type,
  };
};

const getFieldValueMediaVideoFile = (field) => {
  const fieldValue = getFieldValueFromArray(field);

  if (!fieldValue) {
    return null;
  }

  return {
    url: fieldValue,
  };
};

const getFieldValueMediaRemoteVideoFile = (field) => {
  const fieldValue = field;

  if (!fieldValue) {
    return null;
  }

  return {
    url: fieldValue,
  };
};

const getFieldValueMediaImage = (field) => {
  const fieldValue = getFieldValueFromArray(field);

  if (!fieldValue) {
    return null;
  }

  const { url, alt } = fieldValue;

  const extension = url.split(".").pop();

  const images = extension === "gif" ? [url] : parseBreakpoints(fieldValue);

  return {
    url,
    images,
    alt,
  };
};

const parseBreakpoints = (imageData) => {
  const images = Object.entries(imageData)
    .filter((entry) => {
      const [key] = entry;

      return key.startsWith("breakpoint_");
    })
    .map((entry) => {
      const [key, value] = entry;

      const breakpoint = parseInt(key.replace("breakpoint_", ""), 10);

      return [breakpoint, value];
    })
    .sort((a, b) => b[0] - a[0])
    .map((item) => {
      if (item.length <= 0) {
        return null;
      }

      return item[1];
    })
    .filter(Boolean);

  return images;
};

const getFieldValueRelacionados = (field) => {
  if (!field || (field && field.length <= 0)) {
    return null;
  }

  const list = field.map((item) => {
    const {
      nid,
      title,
      intro,
      fecha,
      imagen_listado,
      tematica,
      url,
      enlace_mas_info,
    } = item;

    const image = !imagen_listado
      ? item?.image ?? null
      : { type: "component", data: getFieldValueMultimedia(imagen_listado) };

    return {
      nid,
      title,
      intro,
      fecha,
      image,
      tematica,
      url,
      enlace_mas_info,
    };
  });

  return list;
};

const formatFieldValueVistaResults = (rows = []) => {
  if (rows.length <= 0) {
    return [];
  }

  const list = rows.map((item, key) => {
    const {
      title,
      field_intro,
      field_fecha,
      field_imagen_listado,
      field_tematica,
      view_node,
    } = item;

    const url = view_node ? getUnlocalizedUrl(view_node) : null;

    return {
      id: key,
      title: encodeHtml(title),
      intro: field_intro,
      fecha: field_fecha,
      image: {
        type: "markup",
        data: field_imagen_listado,
      },
      tematica: field_tematica,
      url,
    };
  });

  return list;
};

const getFieldValueVista = (field) => {
  if (field?.rows?.length <= 0) {
    return null;
  }

  const fieldValue = getFieldValueFromArray(field);

  const { rows } = fieldValue;

  return formatFieldValueVistaResults(rows);
};

const getFieldsFromVista = (field) => {
  return getFieldValueFromArray(field);
};

const getFieldValueForm = (field) => {
  const fieldValue = getFieldValueFromArray(field);

  if (!fieldValue) {
    return null;
  }

  const { id, elements } = fieldValue;

  if (!elements) {
    return null;
  }

  return { formId: id, elements };
};

const formatComponentName = (type) => {
  if (!type) {
    return "";
  }

  return type.split("_").filter(Boolean).map(capitalizeFirstLetter).join("");
};

const getFieldLink = (field) => {
  const fieldValue = getFieldValueFromArray(field);

  if (!fieldValue || !fieldValue.uri) {
    return null;
  }

  return {
    title: fieldValue.title ?? "",
    uri: fieldValue.uri ?? "",
  };
};

const getFieldValueExposiciones = (field) => {
  if (field?.length <= 0) {
    return null;
  }

  const list = field?.map((item) => {
    const {
      id,
      field_titulo,
      field_intro,
      field_lugar,
      field_multimedia,
      field_enlace,
    } = item;

    const intro = field_intro[0] ? getFieldValueHtml(field_intro[0]) : null;

    const title =
      field_titulo[0] && isString(field_titulo[0]) ? field_titulo[0] : null;

    const place =
      field_lugar[0] && isString(field_lugar[0]) ? field_lugar[0] : null;

    const media = getFieldValueMultimedia(field_multimedia);

    const url = field_enlace?.[0]?.uri ?? null;

    return {
      id,
      intro,
      title,
      place,
      media,
      url,
    };
  });

  return list;
};

const formatItemsFromAgrupaciones = (field) => {
  if (!field || field.length <= 0) {
    return;
  }

  const formattedItems = field.map((item) => {
    const {
      nid,
      title,
      anos,
      imagen_listado,
      pasada,
      url,
      type,
      etiqueta,
      fecha,
      tipo_agrupacion,
    } = item;

    const image = getFieldValueMultimedia(imagen_listado);

    return {
      nid,
      title,
      anos,
      image: {
        type: "component",
        data: image,
      },
      pasada,
      type,
      url,
      etiqueta,
      fecha,
      tipo_agrupacion,
    };
  });

  return formattedItems;
};

const getFormattedActivities = (
  activities,
  isItPast = false,
  order = "asc"
) => {
  // Filter activities by past ones
  if (isItPast !== "all") {
    const isPast = isItPast ? "1" : "0";
    activities = activities.filter((activity) => activity.pasada === isPast);
  }

  const formattedActivities = activities.map((activity) => {
    const {
      nid,
      title,
      intro,
      imagen_listado,
      tipologia,
      url,
      type,
      anos,
      etiqueta,
      tipo_actividad,
      tipo_agrupacion,
      fecha,
      fecha_texto,
      fecha_auto,
      lugar,
      hora,
      idiomas,
      espacio,
      txt_tercer_nivel,
      fecha_value: dateOrder,
      fechas,
    } = activity;

    const eventTime = getEventTime(fechas);

    const image = getFieldValueMultimedia(imagen_listado);

    const tag = etiqueta ?? tipo_actividad ?? tipo_agrupacion;

    const date = fecha_texto ? fecha_texto : fecha_auto;

    const generateLanguages = (idiomas) => {
      if (Array.isArray(idiomas) && idiomas.length > 0) {
        return idiomas.join(", ");
      } else if (typeof idiomas === "string" && idiomas.trim() !== "") {
        return idiomas;
      } else {
        return null;
      }
    };

    const languages = generateLanguages(idiomas);

    return {
      nid,
      title,
      intro,
      image: {
        type: "component",
        data: image,
      },
      tipologia,
      type,
      url,
      yearsForFilter: anos,
      tag,
      date,
      lugar,
      hora,
      languages,
      fecha,
      espacio,
      txt_tercer_nivel,
      dateOrder,
      eventTime,
    };
  });

  if (order === "asc") {
    formattedActivities.sort((a, b) => {
      const dateComparison = b.dateOrder - a.dateOrder;

      if (dateComparison !== 0) {
        return dateComparison;
      }

      const eventTimeA = a.eventTime ?? Infinity;
      const eventTimeB = b.eventTime ?? Infinity;

      return eventTimeA - eventTimeB;
    });
  } else {
    formattedActivities.sort((a, b) => {
      const dateComparison = a.dateOrder - b.dateOrder;

      if (dateComparison !== 0) {
        return dateComparison;
      }

      const eventTimeA = a.eventTime ?? Infinity;
      const eventTimeB = b.eventTime ?? Infinity;

      return eventTimeB - eventTimeA;
    });
  }

  return formattedActivities;
};

const getYearsFilter = (activities, t, defaultElementString) => {
  let years = [];
  activities.forEach((year) => {
    const { yearsForFilter } = year;
    const splittedYears = yearsForFilter.split(",");
    years = years.concat(splittedYears);
  });

  // remove duplicates
  years = years.filter((value, index) => {
    return years.indexOf(value) === index;
  });
  years.sort((a, b) => b - a);

  years = years.map((year) => {
    return {
      value: year,
      text: year,
    };
  });

  // Add first element
  years.unshift({
    value: "all",
    text: t(defaultElementString),
  });

  return years;
};

const formatOffers = (items) => {
  if (!items || items.length <= 0) {
    return;
  }

  const offers = items.map((item) => {
    const { nid, fecha, intro, title, url } = item;

    return { nid, date: fecha, intro, title, url: url };
  });

  return offers;
};

const getBackgroundColor = (field) => {
  if (!field || field.length <= 0) {
    return null;
  }

  return field?.[0]?.field_color?.[0]?.color ?? null;
};

export {
  getFieldValueFromArray,
  getFieldValueText,
  getFieldValueHtml,
  getFieldValueCarruselItems,
  getFieldValueMultimedia,
  getFieldValueMediaImage,
  getFieldValueRelacionados,
  getFieldValueVista,
  formatFieldValueVistaResults,
  getFieldValueForm,
  formatComponentName,
  getFieldLink,
  getFieldValueExposiciones,
  getFieldsFromVista,
  formatItemsFromAgrupaciones,
  getFormattedActivities,
  getYearsFilter,
  formatOffers,
  getBackgroundColor,
};

const getEventTime = (fechas) => {
  if (!Array.isArray(fechas) || fechas.length === 0) {
    return null;
  }

  const fieldHoraDesde = fechas?.[0]?.field_hora_desde;
  if (!Array.isArray(fieldHoraDesde) || fieldHoraDesde.length === 0) {
    return null;
  }

  const timeString = fieldHoraDesde[0]?.time || null;
  if (!timeString) {
    return null;
  }

  const [hours, minutes] = timeString.split(":").map(Number);
  return hours * 60 + minutes;
};
