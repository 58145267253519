import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal/Modal";
import { Heading } from "../Heading/Heading";

import modalStyles from "../../components/Modal/Modal.module.scss";

const LanguagePopUp = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const hasAccepted = localStorage.getItem("popupAccepted");
    const userLang = navigator.language || "en";

    if (
      !hasAccepted &&
      !userLang.startsWith("es") &&
      !userLang.startsWith("eu")
    ) {
      setIsModalOpen(true);
    }
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem("popupAccepted", "true");
    setIsModalOpen(false);
  };

  if (!isModalOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isModalOpen}
      className={`${modalStyles["content--default"]} ${modalStyles["content--plain"]}`}
      onRequestClose={handleCloseModal}
    >
      <Heading tag="h2" size={"m"}>
        {t("This page isn’t available in English")}
      </Heading>
      <p>
        {t("You can continue browsing the Spanish version of our website.")}
      </p>
      <p>
        {t(
          "For exhibitions pages, an English guide is available for download."
        )}
      </p>
    </Modal>
  );
};

export default LanguagePopUp;
