import { useContext } from "react";

import PropTypes from "prop-types";

import LanguageSwitcher from "../../../../../components/LanguageSwitcher/LanguageSwitcher";
import { MenuContext } from "../../../../../context/MenuContext";
import LoginAndSuscribe from "../../../../../elements/LoginAndSuscribe/LoginAndSuscribe";
import useNodeData from "../../../../../hooks/use-node-data";

import styles from "./SecondaryMenu.module.scss";

const SecondaryMenu = ({ openWebformModal }) => {
  const { getNodeData } = useNodeData();
  const nodeData = getNodeData();
  const { isScrolled, mouseenter } = useContext(MenuContext);

  return (
    <nav className={styles.secondaryMenu} aria-label="secondary">
      <LoginAndSuscribe
        scrolled={isScrolled}
        mouseenter={mouseenter}
        openWebformModal={openWebformModal}
      />
      {/* <SearchButton />*/}
      <LanguageSwitcher
        scrolled={isScrolled}
        mouseenter={mouseenter}
        localizedSlugs={nodeData?.urls || {}}
      />
    </nav>
  );
};

SecondaryMenu.propTypes = {
  openWebformModal: PropTypes.func,
};

export default SecondaryMenu;
